import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "./Header.css";
import whitepaper from "../images/WhitePaper.pdf";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header-container">
      <Link to="/" className="logo-link">
        <div className="logo-container">
          <img src={logo} alt="SLOTH" />
          <h1 className="logo-text">$SLOTH</h1>
        </div>
      </Link>
      <nav className={`nav-container ${isActive ? "active" : ""}`}>
        <Link
          className="nav-item"
          to="https://swap.slothmeme.com/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
        >
          SleepySwap
        </Link>
        <Link className="nav-item" to={whitepaper} target="_blank">
          WhitePaper
        </Link>
        <Link
          className="nav-item"
          to="https://lazytrack.slothmeme.com/"
          target="_blank"
        >
          LazyTrack
        </Link>
      </nav>
      <div className={`mobile-nav-container ${isActive ? "active" : ""}`}>
        <Link
          className="nav-item"
          to="https://swap.slothmeme.com/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
        >
          SleepySwap
        </Link>
        <Link className="nav-item" to={whitepaper} target="_blank">
          White Paper
        </Link>
        <Link
          className="nav-item"
          to="https://lazytrack.slothmeme.com/"
          target="_blank"
        ></Link>
      </div>
      <button>
        <a></a>
        <a
          href="https://pancakeswap.finance/swap?outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
          target="_blank"
          rel="noreferrer"
        >
          GET $SLOTH
        </a>
      </button>
      <div
        className={`burger-menu ${isActive ? "active" : ""}`}
        onClick={handleToggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Header;
