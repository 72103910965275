import React from "react";
import "./Roadmap.css";

const Roadmap = () => {
  const stages = [
    {
      title: "PHASE 1",
      items: [
        { name: "√ Fair Launch ", class: "phase1-item-done" },
        { name: "√ Liquidity Lock for 42 Years", class: "phase1-item-done" },
        { name: "√ Renounce Ownership", class: "phase1-item-done" },
        { name: "- CoinGecko Listings", class: "phase1-item" },
        { name: "√ Coinmarketcap Listings", class: "phase1-item-done" },
        { name: "- Attract 1,000 $SLOTH Holders", class: "phase1-item" },
        {
          name: "- Get $SLOTH Trending on Twitter",
          class: "phase1-item",
        },
        { name: "√ SlothSwap", class: "phase1-item-done" },
        { name: "√ Call Groups", class: "phase1-item-done" },
        { name: "√ LazyTrack", class: "phase1-item-done" },
      ],
    },
    {
      title: "PHASE 2",
      items: [
        { name: "- Community Partnerships", class: "phase2-item" },
        { name: "- Utility Partnerships", class: "phase2-item" },
        { name: "- CEX Listings", class: "phase2-item" },
        { name: "- 10,000+holders", class: "phase2-item" },
        { name: "- SLOTH NFT Collection", class: "phase2-item" },
      ],
    },
    {
      title: "PHASE 3",
      items: [
        { name: "- T1 Exchange Listings", class: "phase3-item" },
        { name: "- 100,000 Holders", class: "phase3-item" },
        { name: "- Flip Memes", class: "phase3-item" },
      ],
    },
  ];

  const renderItems = (items) => {
    return items.map((item, index) => (
      <div className={`item ${item.class}`} key={index}>
        <div className="item-title">{item.name}</div>
      </div>
    ));
  };

  const renderStages = () => {
    return stages.map((stage, index) => (
      <div className="stage" key={index}>
        <div className="stage-title">{stage.title}</div>
        <div className="stage-items">{renderItems(stage.items)}</div>
      </div>
    ));
  };

  return (
    <div className="roadmap-container">
      <div className="roadmap">{renderStages()}</div>
    </div>
  );
};

export default Roadmap;
