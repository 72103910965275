import React from "react";
import "./Footer.css";
import { FaTelegram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      <div className="left-section">
        {/* <a href="https://www.dextools.io/" target="_blank" rel="noreferrer">
          DexTools
        </a> */}
        <a
          href="https://bscscan.com/token/0xb60617173471203ee7cf66ab869a78d50cde733b"
          target="_blank"
          rel="noreferrer"
        >
          BSCscan
        </a>
      </div>
      <div className="center-section">
        <p>&copy; {currentYear} SLOTH</p>
      </div>
      <div className="right-section">
        <a href="https://t.me/slothmeme" target="_blank" rel="noreferrer">
          <FaTelegram className="social-icon" />
        </a>
        <a
          href="https://twitter.com/SlothMeme_BSC"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter className="social-icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
