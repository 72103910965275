import React, { useState } from "react";
import "./TokenDistribution.css";

const TokenDis = () => {
  const totalSupply = 450;
  const [burned, setBurned] = useState(210);

  const liquidity = 30.3;
  const burnAmount = 69;
  const devAmount = 0.5;

  const progress = ((totalSupply - burned) / totalSupply) * 100;

  return (
    <div className="token-tokenomics-container">
      <div className="token-tokenomics-section">
        <h2>TOKENS DISTRIBUTION</h2>
        <div className="token-tax-container">
          <div className="token-tax-item">
            <div className="token-tax-percentage">{`${liquidity}%`}</div>
            <div className="token-tax-label">Liquidity</div>
          </div>
          <div className="token-tax-item">
            <div className="token-tax-percentage">{`${burnAmount}%`}</div>
            <div className="token-tax-label">Token Burns</div>
          </div>
          <div className="token-tax-item">
            <div className="token-tax-percentage">{`${devAmount}%`}</div>
            <div className="token-tax-label">Developers</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenDis;
