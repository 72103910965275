import React, { useState } from "react";
import "./Main.css";
import mainImage from "../images/main-image.png";
import twitterImage from "../images/twitter.png";
import telegramImage from "../images/Telegram.png";
import dextoolImage from "../images/dextools.webp";
import bscImage from "../images/BSCscan.webp";
import pancakeImage from "../images/pancake.png";
import cmcImage from "../images/cmc.png"

const Main = () => {
  const [address, setAddress] = useState(
    "0xb60617173471203eE7cF66aB869a78D50cdE733B"
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    alert("Address copied to clipboard");
  }; 

  return (
    <div className="main-container">
      <div className="text-container">
        <h2>INVEST IN SLOW MOTION, PROFIT IN HIGH DEFINITION</h2>
        <p>
          Welcome to Sloth - the crypto that's all about making money without
          breaking a sweat! With Sloth, you can sit back, relax, and watch your
          investment grow steadily over time. No need to rush or stress - just
          let Sloth do the work for you while you enjoy the lazy life. So join
          the Sloth squad and start investing the easy way!
        </p>
        <div className="button-container">
          <button class="circular-button">
            <a href="https://twitter.com/SlothMeme_BSC" target="_blank">
              <img src={twitterImage} alt="Button Image"></img>
            </a>
          </button>
          <button class="circular-button">
            <a href="https://t.me/slothmeme" target="_blank">
              <img src={telegramImage} alt="Button Image"></img>
            </a>
          </button>
          <button class="circular-button">
            <a
              href="https://www.dextools.io/app/en/bnb/pair-explorer/0x59dc1667b78739badbef251e9bbcd9d32209b535"
              target="_blank"
            >
              <img src={dextoolImage} alt="Button Image"></img>
            </a>
          </button>
          <button class="circular-button">
            <a
              href="https://bscscan.com/token/0xb60617173471203ee7cf66ab869a78d50cde733b"
              target="_blank"
            >
              <img src={bscImage} alt="Button Image"></img>
            </a>
          </button>
          <button class="circular-button">
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
              target="_blank"
            >
              <img src={pancakeImage} alt="Button Image"></img>
            </a>
          </button>
          <button class="circular-button">
            <a
              href="https://coinmarketcap.com/currencies/sloth-meme/"
              target="_blank"
            >
              <img src={cmcImage} alt="Button Image"></img>
            </a>
          </button>
        </div>
        <div className="copybutton1">
          <input type="text" readonly value={address} />
          <button className="button" onClick={handleCopy}>
            Copy to Clipboard
          </button>
        </div>
      </div>
      <div className="image-container">
        <img src={mainImage} alt="Main" />
      </div>
    </div>
  );
};

export default Main;
