import React, { useState } from "react";
import "./Tokenomics.css";
import sloth1 from "../images/sloth.png";

const Tokenomics = () => {
  const totalSupply = 450;
  const [burned, setBurned] = useState(210);

  const rewardsTax = 3;
  const marketingTax = 5;

  const progress = ((totalSupply - burned) / totalSupply) * 100;

  return (
    <div className="tokenomics-container2">
      <img src={sloth1}></img>
      <div className="tokenomics-container">
        <div className="tokenomics-section">
          <h2>SUPPLY</h2>
          <div className="total-supply-label">{`420 QUADRILLION`}</div>
          <div className="total-supply-label">{`69% BURNT`}</div>
        </div>
        <div className="tokenomics-section">
          <h2>TAX</h2>
          <div className="tax-container">
            <div className="tax-item">
              <div className="tax-percentage">{`${rewardsTax}%`}</div>
              <div className="tax-label">Active Rewards</div>
            </div>
            <div className="tax-item">
              <div className="tax-percentage">{`${marketingTax}%`}</div>
              <div className="tax-label">Marketing</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
