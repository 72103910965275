import React from "react";
import "./Community.css";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import sloth2 from "../images/sloth2.png";

const Community = () => {
  return (
    <div className="community-container">
      <div className="community-content">
        <h2>Join $SLOTH Community</h2>
        <div className="com-button-container">
          <a href="https://t.me/slothmeme" target="_blank">
            <div className="button">
              <a>
                <FaTelegram className="button-icon" />
              </a>
            </div>
          </a>
          <a href="https://twitter.com/SlothMeme_BSC" target="_blank">
            <div className="button">
              <a>
                <FaTwitter className="button-icon" />
              </a>
            </div>
          </a>
        </div>
        <img src={sloth2}></img>
      </div>
    </div>
  );
};

export default Community;
