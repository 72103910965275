import React, { useState } from "react";
import "./Exchanges.css";
import azbit from "../images/azbit.png";
import tokpie from "../images/tokpie.png";
import swap from "../images/swap.png";
import sleepy from "../images/sleepy.png";

const Exchanges = () => {
  return (
    <div className="exchanges-container">
      <div className="exchanges-section">
        <h2>GET $SLOTH FROM</h2>
        <div className="exchanges">
          <div className="exchange-item">
            <a
              href="https://dashboard.azbit.com/exchange/SLOTH_MUSDT"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="exchange-image"
                src={azbit}
                alt="azbit-exchange"
              ></img>
            </a>
          </div>
          <div className="exchange-item">
            <a
              href="https://tokpie.com/view_exchange/SLOTH-USDT/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="exchange-image"
                src={tokpie}
                alt="azbit-exchange"
              ></img>
            </a>
          </div>
          <div className="exchange-item">
            <a
              href="https://swap.slothmeme.com/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="exchange-image"
                src={sleepy}
                alt="azbit-exchange"
              ></img>
            </a>
          </div>
          <div className="exchange-item">
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0xb60617173471203eE7cF66aB869a78D50cdE733B"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="exchange-image"
                src={swap}
                alt="azbit-exchange"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exchanges;
