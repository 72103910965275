import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import Tokenomics from "./components/Tokenomics";
import TokenDis from "./components/TokenDistribution";
import Roadmap from "./components/Roadmap";
import Community from "./components/Community";
import Footer from "./components/Footer";
import Exchanges from "./components/Exchanges"

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Tokenomics />
      <TokenDis />
      <Roadmap />
      <Exchanges/>
      <Community />
      <Footer />
    </div>
  );
}

export default App;
